<template>
  <div class="batch-processing">
    <kendo-splitter
      :ref="'vSplitter1'"
      class="batch-processing-main"
      :panes="vSplitter1.panes"
      :orientation="vSplitter1.orientation"
      :scrollable="vSplitter1.scrollable">
      <kendo-splitter
        :ref="'hSplitter1'"
        class="batch-processing-main"
        :panes="hSplitter1.panes"
        :orientation="hSplitter1.orientation"
        :scrollable="hSplitter1.scrollable">
        <SplitterContainer title="Decision" component="actions-batch-processing"/>
        <SplitterContainer title="" component="filters-batch-processing" selected="false"/>
      </kendo-splitter>
      <SplitterContainer title="Citations List" component="publication-list-batch-processing"/>
    </kendo-splitter>
  </div>
</template>

<script>
import SplitterContainer from '@/containers/SplitterContainer'

export default {
  name: 'batch-processing',
  components: {
    SplitterContainer
  },
  data () {
    return {
      vSplitter1: {
        scrollable: false,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '27%' },
          { collapsible: true, size: '73%' }
        ]
      },
      hSplitter1: {
        scrollable: false,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '40%' },
          { collapsible: true, size: '60%' }
        ]
      }
    }
  },
  mounted: function () {
    this.$store.state.selectedNavigation = 'process'
    this.$store.state.selectedAction = 'batch-processing'
    this.$store.state.showActions = true
    this.$refs.vSplitter1.kendoWidget().resize()
  }
}
</script>
